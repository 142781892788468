@import url('https://fonts.googleapis.com/css2?family=UnifrakturMaguntia');

* {
  margin: 0;
  font-family: Arial, sans-serif;
}

body {
  background: linear-gradient(
    to bottom,
    #020921 0%,
    #020921 143px,
    #000312 144px,
    #000312 100%
  );
  background-attachment: fixed;
  background-color: black;
  color: white;
  font-size: 0.8rem;
}

.site {
  max-width: 844px;
  margin: auto;
}

.container {
  background-color: #000312;
  box-shadow: 0 0.2rem 1.5rem black;
}

/* Common Utilities */

h1 {
  font-size: 1.2rem;
}

h2 {
  font-size: 0.8rem;
  font-weight: lighter;
}

.fraktur {
  font-family: 'UnifrakturMaguntia', serif;
  font-size: 1.5rem;
  padding: 0.06rem 1rem;
  background-color: rgba(0, 0, 0, 0);
}

.tahoma {
  font-family: Tahoma, sans-serif;
  font-weight: bold;
}

.vers {
  font-size: 0.6rem;
}

.border {
  border: 0.1rem solid #121921;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 1rem;
}

.border-btn {
  border: 0.01rem solid #8f8f8f;
  border-radius: 0.75rem;
}

/* Header and Footer */

header {
  height: 178px;
  width: 100%;
  background: no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  width: 19.5rem;
  height: auto;
  margin: 0.4rem 1rem;
}

nav {
  padding: 0.25rem 1rem 0.1rem;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.7);
}

footer p {
  font-size: 0.55rem;
  color: #686868;
  padding: 0.25rem 0.5rem;
}

/* General Page Styles */

main {
  padding: 0.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
}

main > * {
  margin: 0.75rem;
}

#catalogue,
#authors {
  flex-wrap: wrap;
}

#authors,
#contact {
  justify-content: space-around;
}

/* Books and Authors */

#authors {
  padding-left: 0;
  padding-right: 0;
}

#authors > * {
  margin: 0;
}

.content {
  display: grid;
}

figure {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.cover {
  margin-right: 2rem;
}

aside {
  grid-column: 1 / span 1;
  grid-row: 2 / span 2;
  margin-top: 1.65rem;
}

.btn-aside {
  padding: 0.75rem 2rem;
}

aside p {
  font-size: 0.65rem;
  color: #686868;
}

.howtobuy {
  margin: 2.4rem 0.2rem 0;
  width: 12rem;
}

section {
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
}

section > div {
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
}

section > div:last-child {
  margin-bottom: 0;
}

.specs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btn-buy {
  padding: 0.75rem 0;
}

.btn-buy a {
  padding: 0.75rem;
}

.btn-buy a:nth-child(2) {
  border-left: 0.01rem solid #8f8f8f;
}

.index {
  background-color: #fff0ed;
  margin: 0.75rem 0;
  padding: 0.75rem;
  border-radius: 0.75rem;
  color: #000;
}

.index p,
.index span.gray {
  margin: 0 0.75rem;
  font-style: italic;
  font-weight: normal;
}

.index span {
  margin-left: -0.75rem;
  font-style: normal;
  font-weight: bold;
}

.index span.gray {
  color: #444;
  margin: 0;
}

/* Related */

.related {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
}

.related > div {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.related .rel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.cardrel {
  font-size: 0.65rem;
  max-width: 9rem;
  text-align: center;
}

/* Contact */

#contact {
  text-align: center;
}

.msg {
  width: 14rem;
  margin: auto;
  font-weight: bold;
}

.msg-warning {
  color: yellowgreen;
}

.msg-error {
  color: firebrick;
}

form > div > label {
  text-align: right;
  width: 3.5rem;
  font-weight: bold;
  display: inline-block;
}

input,
textarea,
select {
  width: 14rem;
  margin: 0.3rem 0.5rem;
  border-radius: 0;
  vertical-align: middle;
}

select {
  width: 14.45rem;
}

textarea {
  font-size: 0.85rem;
}

.btn-send {
  padding: 0.25rem 0.25rem 0.1rem;
  width: 95%;
  background-color: #121921;
}

.social > a {
  font-size: 1.5rem;
  margin: 0.75rem;
}

/* Error404 */

.error {
  width: 100%;
  text-align: center;
  padding-bottom: 1.4rem;
}

.error .e404 {
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Images Styles */

figure img,
.cardrel img,
.filler {
  width: 12rem;
  height: auto;
  box-shadow: 0.15rem 0.15rem 0.1rem white;
}

#authors img {
  width: 13rem;
  height: auto;
}

.cardrel img {
  width: 6rem;
  margin: 1.25rem 1.5rem 0.25rem;
}

.social img {
  width: 5rem;
  margin: 0;
}

.filler {
  height: 0;
  margin: 0 0.75rem;
}

/* Links and Hovers */

a,
.fraktur,
.shade {
  text-decoration: none;
  color: #8f8f8f;
}
a:hover,
.fraktur:hover {
  color: white;
}
a > img,
.logo,
.fraktur {
  border: 0;
  cursor: pointer;
}
*:focus {
  outline: 0;
}
#authors img:hover {
  filter: drop-shadow(0.003rem 0.003rem 0.1rem #fff0ed);
}
.btn-send:hover {
  background-color: #161d24;
}
.fade,
.unfade:hover {
  opacity: 1;
}
.fade:hover,
.unfade {
  opacity: 0.6;
}
.book:hover {
  filter: grayscale(100%);
}

/* Responsive Media Queries */

@media screen and (max-width: 844px) {
  .specs {
    flex-flow: column;
    align-items: flex-start;
  }
  .btn-buy {
    margin-top: 1.25rem;
  }
}

@media screen and (max-width: 724px) {
  main {
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 602px) {
  header {
    position: fixed;
    z-index: 1;
  }
  .logo {
    margin-left: 1.15rem;
  }
  header,
  nav,
  main,
  .content {
    text-align: center;
  }
  main {
    padding-top: 190px;
  }
  .content {
    flex-direction: column;
  }
  .cover,
  .index span {
    margin: 0;
  }
  section {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .specs {
    align-items: center;
  }
  .related {
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
  }
  aside {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    padding: 0.75rem 0;
    margin-top: 0.5rem;
  }
  .howtobuy {
    width: auto;
  }
  .none {
    display: none;
  }
  form > div > label {
    display: block;
    text-align: center;
    margin: 0.5rem auto 0;
  }
  form > div:first-child > label {
    margin-top: 0;
  }
  footer {
    text-align: center;
  }
}

@media screen and (max-width: 402px) {
  header {
    height: 124px;
  }
  .logo {
    width: 80%;
  }
  nav {
    padding: 0.25rem 0.25rem 0.1rem;
    display: flex;
    justify-content: space-evenly;
  }
  .fraktur {
    font-size: 1.3rem;
  }
  main {
    padding-top: 136px;
  }
  aside > a {
    margin: -0.75rem 0;
    display: block;
  }
}

@media screen and (max-width: 342px) {
  .btn-buy > a {
    display: block;
    justify-content: center;
    margin: -0.75rem 0;
  }
  .btn-buy > a:nth-child(2) {
    border-left: 0rem;
    border-top: 0.01rem solid #8f8f8f;
    border-radius: 0 0 0.75rem 0.75rem;
    margin: 0.75rem 0 -0.75rem;
  }
}
